import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import { Element } from 'react-scroll';

import AboutImg1 from '../assets/images/icons/icon-measure.svg';
import AboutImg2 from '../assets/images/icons/icon-light.svg';
import AboutImg3 from '../assets/images/icons/icon-sound.svg';
import BgOctagon from '../assets/images/icons/icon-octagon.svg';

const About = () => {

	const { t } = useTranslation();

	const content = [
		{
			title: t('about.content.about1.title'),
			description: t('about.content.about1.description'),
			image: AboutImg1,
		},
		{
			title: t('about.content.about2.title'),
			description: t('about.content.about2.description'),
			image: AboutImg2,
		},
		{
			title: t('about.content.about3.title'),
			description: t('about.content.about3.description'),
			image: AboutImg3,
		}
	];

	return (
		<Element
			className="w-full"
			name="about"
			id="about"
		>
			<Container>
				<div className="w-full grid lg:grid-cols-3 gap-4 lg:gap-8">
					{content.map((item, index) => (
						<div className="lg:border-4 border-gray-200 rounded-md p-8 mt-8 lg:mt-14 text-center" key={index}>
							<div className="flex flex-col items-center justify-center gap-4 group">
								{item.image && (
									<figure
										className="w-32 h-32 lg:w-24 lg:h-24 p-8 lg:p-6 lg:-mt-20"
										style={{ backgroundImage: `url(${BgOctagon})`, backgroundSize: 'contain', backgroundPosition: 'center' }}
									>
										<img
											src={item.image}
											alt={item.title}
											className="w-full h-full object-contain"
										/>
									</figure>
								)}
								<h3 className="text-primary font-poppins text-2xl font-bold">{item.title}</h3>
								<p className="text-gray-700 font-poppins">{item.description}</p>
							</div>
						</div>
					))}
				</div>
			</Container>
		</Element>
 	);
}

export default About