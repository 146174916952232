import React from 'react'
import { Element } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'
import ContactLine from '../components/ContactLine'
import RegisterForm from './RegisterForm'
import SocialIcons from '../components/SocialIcons'
import useWhatsapp from '../data/whatsapp'

const Contact = () => {

    const { t } = useTranslation()
	const { number: whatsAppNumber, text: whatsAppMsgText } = useWhatsapp({ text: t('whatsapp.default') });
    
    return (
        <div className="w-full">
            <Container>
                <h2 className="mb-4 lg:mb-8 text-primary font-poppins font-bold text-2xl lg:text-5xl max-w-[420px]">{t('contact.title')}</h2>
            </Container>
            <Element className="w-full" name="contact" id="contact">
                <div className="bg-gray-800 overflow-hidden">
                    <div className="grid py-8 lg:py-16 lg:grid-cols-2 max-lg:gap-4">
                        <div className="text-white p-8">
                            <div
                                className="flex items-center justify-start flex-col lg:py-8 gap-4 lg:gap-6"
                                itemScope
                                itemType="https://schema.org/LocalBusiness"
                            >
                                <h2
                                    className="text-xl lg:text-4xl uppercase text-white mb-2 w-full font-bold font-roboto"
                                    itemProp="name"
                                >
                                    {process.env.REACT_APP_NAME}
                                </h2>

                                {(process.env.REACT_APP_FACEBOOK || process.env.REACT_APP_INSTAGRAM || process.env.REACT_APP_YOUTUBE || process.env.REACT_APP_LINKEDIN) && (
                                    <ContactLine
                                        title={t('contact.social-media')}
                                        className="flex items-center justify-start pt-2 flex-row gap-4"
                                    >
                                        <SocialIcons />
                                    </ContactLine>
                                )}
                                
                                {process.env.REACT_APP_EMAIL && (
                                    <ContactLine title={t('contact.email')}>
                                        <a
                                            href={`mailto:${process.env.REACT_APP_EMAIL}`}
                                            itemProp="email"
                                        >
                                            {process.env.REACT_APP_EMAIL}
                                        </a>
                                    </ContactLine>
                                )}

                                {process.env.REACT_APP_PHONE && (
                                    <ContactLine title={t('contact.phone')}>
                                        <a
                                            href={`tel:${process.env.REACT_APP_PHONE.match('/|d+\g')}`}
                                            itemProp="telephone"
                                            target="_blank"
                                        >
                                            {process.env.REACT_APP_PHONE}
                                        </a>
                                    </ContactLine>
                                )}
                                
                                {process.env.REACT_APP_WHATSAPP && (
                                    <ContactLine title={t('contact.whatsapp')}>
                                        <a
                                            href={`wa.me/${whatsAppNumber}?text=${whatsAppMsgText}`}
                                            itemProp="telephone"
                                            target="_blank"
                                        >
                                            {process.env.REACT_APP_WHATSAPP}
                                        </a>
                                    </ContactLine>
                                )}

                                {process.env.REACT_APP_ADDRESS && (
                                    <ContactLine title={t('contact.address')}>
                                        <a
                                            className="w-full hover:no-underline group"
                                            itemProp="address"
                                            itemScope
                                            itemType="https://schema.org/PostalAddress"
                                            href={process.env.REACT_APP_ADDRESS_URL}
                                            target="_blank"
                                        >
                                            <span itemProp="streetAddress">{process.env.REACT_APP_ADDRESS}</span>
                                            {process.env.REACT_APP_ADDRESS_REGION && 
                                                <>
                                                   {` - `}<span itemProp="addressRegion">{process.env.REACT_APP_ADDRESS_REGION}</span>
                                                </>
                                            }
                                            {process.env.REACT_APP_ADDRESS_CITY_STATE &&
                                                <>
                                                    <br />
                                                    <span itemProp="addressRegion">{process.env.REACT_APP_ADDRESS_CITY_STATE}</span>
                                                </>
                                            }
                                            {process.env.REACT_APP_POSTAL_CODE &&
                                                <>
                                                    <br />
                                                    CEP <span itemProp="postalCode">{process.env.REACT_APP_POSTAL_CODE}</span>
                                                </>
                                            }
                                            {process.env.REACT_APP_ADDRESS_URL && (
                                                <div className="w-full">
                                                    <meta itemProp="url" content={process.env.REACT_APP_ADDRESS_URL} />
                                                    <div className="bg-yellow-500 text-slate-800 p-1 lg:px-2 mt-2 uppercase text-sm font-poppins font-bold rounded-md float-left hover:bg-orange-500 hover:text-gray-100 hover:shadow-md hover:-rotate-2 transition-all">{t('contact.button')}</div>
                                                </div>
                                            )}
                                        </a>
                                    </ContactLine>
                                )}
                            </div>
                        </div>
                        <div className="p-8">
                            <div className="p-8 bg-white rounded-md shadow-md">
                                <RegisterForm />
                            </div>
                        </div>
                    </div>

                    {process.env.REACT_APP_GOOGLE_MAPS_EMBED && (
                        <div className="bg-blue-500 w-full">
                            <iframe
                                src={process.env.REACT_APP_GOOGLE_MAPS_EMBED}
                                width="1200"
                                height="450"
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                style={{ border: 0 }}
                                className="w-full h-[500px]"
                            >
                            </iframe>
                        </div>
                    )}
                </div>
            </Element>
        </div>
    )
}

export default Contact