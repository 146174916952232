import React, {useCallback} from 'react'
import { useTranslation } from 'react-i18next'
import HeaderBg from '../assets/images/header-bg.jpg'
import useWhatsapp from '../data/whatsapp'

const HomeHeader = () => {
	const { t, i18n } = useTranslation()
	const { number: whatsAppNumber, text: whatsAppMsgText } = useWhatsapp({ text: 'whatsapp.quote' })

	return (
		<div className="h-screen w-full overflow-hidden bg-gray-100">
			<div className="grid md:grid-cols-2 h-full">
				<div className="p-8 lg:p-16 flex items-center h-full justify-center flex-col">
					<div className="flex items-center md:items-start justify-center pt-16 flex-col gap-4 lg:gap-8 font-poppins max-md:text-center">
						<h1 className="text-primary font-bold text-2xl lg:text-4xl">{t('header.title')}</h1>
						<p className="lg:text-lg">{t('header.description')}</p>
						<a
							className="bg-primary text-white uppercase font-bold h-full rounded-md p-2 lg:p-4 hover:bg-red-700 hover:-rotate-2 hover:shadow-lg transition duration-300 ease-in-out"
							target="_blank"
							href={`https://wa.me/55${whatsAppNumber}?text=${whatsAppMsgText}`}
						>{t('header.button')}</a>
					</div>
				</div>
				<div className="bg-gray-700">
					<img
						src={HeaderBg}
						alt={process.env.REACT_AP_NAME}
						className="w-full h-full object-cover object-center"
					/>
				</div>
			</div>
		</div>
	)
}

export default HomeHeader;