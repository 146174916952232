import React from 'react'
import Icon from './Icon'

type Props = {
    iconClass: string
}

const SocialIcons = (props: Props) => {
    return (
        <>
            {process.env.REACT_APP_FACEBOOK && (
                <a
                    href={process.env.REACT_APP_FACEBOOK}
                    target="_blank"
                >
                    <Icon icon="facebook" className={props.iconClass} />
                </a>
            )}
            {process.env.REACT_APP_INSTAGRAM && (
                <a
                    href={process.env.REACT_APP_INSTAGRAM}
                    target="_blank"
                >
                    <Icon icon="instagram" className={props.iconClass} />
                </a>
            )}
            {process.env.REACT_APP_YOUTUBE && (
                <a
                    href={process.env.REACT_APP_YOUTUBE}
                    target="_blank"
                >
                    <Icon icon="youtube" className={props.iconClass} />
                </a>
            )}
            {process.env.REACT_APP_LINKEDIN && (
                <a
                    href={process.env.REACT_APP_LINKEDIN}
                    target="_blank"
                >
                    <Icon icon="linkedin" className={props.iconClass} />
                </a>
            )}
            {process.env.REACT_APP_GOOGLE_MY_BUSINESS && (
                <a
                    href={process.env.REACT_APP_GOOGLE_MY_BUSINESS}
                    target="_blank"
                >
                    <Icon icon="google" className={props.iconClass} />
                </a>
            )}
        </>
    )
}

SocialIcons.defaultProps = {
    iconClass: 'text-white hover:slate-100',
}

export default SocialIcons