import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Landing from './layouts/Landing';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App: React.FC = () => {
	return (
		<I18nextProvider i18n={i18n}>
			<BrowserRouter>
				<Routes>
					<Route index element={<Landing><Home /></Landing>} />
					<Route path='/privacy-policy' element={<Landing><PrivacyPolicy /></Landing>} />
				</Routes>
			</BrowserRouter>
		</I18nextProvider>
	);
}

export default App;
