import React from 'react'
import { useTranslation } from 'react-i18next';

type Props = {
    text: string
}

interface WhatsApp {
    number: string,
    text: string,
}

const useWhatsapp = (props: Props): WhatsApp => {
    const { t } = useTranslation();

    const whatsApp = process.env.REACT_APP_WHATSAPP
    const whatsAppNumber = whatsApp?.replace(/\D/g,'')
    const whatsAppMsgText = t(props.text).replace(/ /g, '+');

    return {
        number: whatsAppNumber || '',
        text: whatsAppMsgText,
    };
}

useWhatsapp.defaultProps = {
    text: process.env.REACT_APP_WHATSAPP_MESSAGE ?? 'Olá! Gostaria de solicitar um orçamento!',
}

export default useWhatsapp;