import React, { useState } from 'react'
import CountDown from '../components/CountDown'
import { useTranslation } from 'react-i18next'
import useWhatsapp from '../data/whatsapp'

type Props = {
    whatsAppMsg?: string, 
}

const Gift = (props: Props) => {
    const { t } = useTranslation()
	const { number: whatsAppNumber, text: whatsAppMsgText } = useWhatsapp({ text: t('whatsapp.redeem') });

    const closestDate = (): number => {
        const completeDate = new Date().toDateString()
        const now = new Date().getTime();
        const datesInterval = [
            new Date(completeDate + ' 04:00:00').getTime(),
            new Date(completeDate + ' 08:00:00').getTime(),
            new Date(completeDate + ' 12:00:00').getTime(),
            new Date(completeDate + ' 16:00:00').getTime(),
            new Date(completeDate + ' 20:00:00').getTime(),
            new Date(completeDate + ' 23:59:59').getTime(),
        ]
        const closestTime = datesInterval.find(time => time > now);
        return closestTime || now;
    }

    return (
        <div className="grid lg:grid-cols-2 bg-red-500 text-white rounded-md overflow-hidden shadow-lg lg:shadow-xl font-poppins text-center">
            <div className="p-8 lg:p-16 flex items-center justify-center flex-col gap-4 lg:gap-8">
                <h2 className="text-xl lg:text-4xl font-bold">{t('gift.title')}</h2>
                <a
                    href={`https://wa.me/55${whatsAppNumber}?text=${whatsAppMsgText}`}
                    className="bg-white text-red-700 p-4 rounded-md shadow-md uppercase font-semibold hover:rotate-3 transition-all hover:text-slate-800 hover:bg-slate-50 hover:shadow-xl">
                    {t('gift.button')}
                </a>
                <p>{t('gift.subtitle')}</p>
            </div>
            <div className="p-8 lg:p-16 lg:py-24 bg-red-700 flex items-center justify-center flex-col gap-4 lg:gap-8"> 
                <p>{t('gift.description')}</p>
                <CountDown end={closestDate()} />
            </div>
        </div>
    )
}

export default Gift