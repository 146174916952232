import React from 'react'
import { useTranslation } from 'react-i18next'
import HomeHeader from '../partials/HomeHeader'
import About from '../partials/About'
import Contact from '../partials/Contact'
import Features from '../partials/Features'
import Gallery from '../partials/Gallery'
import CompanyGallery from '../partials/CompanyGallery'
import FAQ from '../partials/FAQ'
import Gift from '../partials/Gift'

export default function Home () {

	const { t } = useTranslation();
 	
	return (
		<main id="main">
			<div className="w-full flex flex-col items-center justify-center gap-16 lg:gap-32">
				<HomeHeader />
				<About />
				<CompanyGallery />
				<Gallery />
				<Features />
				<Gift />
				<Contact />
				<FAQ />
			</div>
		</main>
	);
}
