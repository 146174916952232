import React, { useState, useRef } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import InputText from '../components/form/InputText';
import Button from '../components/form/Button';
import FormStatus from '../components/form/FormStatus';
import Texarea from '../components/form/Textarea';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";

interface FormValues {
	name: string;
	company: string;
	email: string;
	mobile_phone: string;
	message: string;
}

const RegisterForm: React.FC = () => {
	const [formStatus, setFormStatus] = useState('idle');
	const { t } = useTranslation();

	const initialValues: FormValues = {
		name: '',
		company: '',
		email: '',
		mobile_phone: '',
		message: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t('validation.name_required')).min(6, t('validation.name_minlength')),
		email: Yup.string().required(t('validation.email_required')).email(t('validation.email_invalid')),
		mobile_phone: Yup.string().required(t('validation.phone_required')).matches(/^\(\d{2}\) \d{5}-\d{3,4}$/, t('validation.phone_invalid')),
		message: Yup.string().min(10, t('validation.message_minlength')),
	});

	const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
		setFormStatus('is_submiting');
		
		const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';
		const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '';
		const key = '2ghafF_RZhLQBj8Ss';
		
		emailjs.send(
			serviceId,
			templateId,
			{
				name: values.name,
				company: values.company,
				email: values.email,
				mobile_phone: values.mobile_phone,
				message: values.message,
			},
			{
				publicKey: key
			}
		).then(
			() => {
				setFormStatus('success');
				setSubmitting(false);
			},
			(error) => {
				setFormStatus('error');
			}
		);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
				setTimeout(() => {
				  handleSubmit(values, formikHelpers);
				}, 1000);
			  }}
		>
			{({ isSubmitting, values, errors, touched, setFieldValue }) => (
				<Form className="relative">
					{formStatus !== 'idle' && (
						<FormStatus
							title={t(`form.${formStatus}`)}
							description={t(`form.${formStatus}_description`)}
						/>
					)}

					<div>
						<InputText name="name" label={t('form.name')} />
					</div>

					<div>
						<InputText name="company" label={t('form.company')} />
					</div>

					<div>
						<InputText name="email" label={t('form.email')} />
					</div>

					<div>
						<InputText name="mobile_phone" label={t('form.phone')} mask="(99) 99999-999?" formatChars={{ 9: '[0-9]', '?': '[-\0-9]' }} />
					</div>

					<div>
						<Texarea name="message" label={t('form.message')} />
					</div>


					<div>
						<Button>{t('form.submit')}</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default RegisterForm;