import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'
import { Element } from 'react-scroll'
import IconCollapseArrow from '../assets/images/icons/icon-collapse-arrow.svg'

interface FAQItem {
    title: string
    description: string
}

type CollapsibleProps = {
    data: {
        title: string,
        content: string
    }[]
}

const FAQ = () => {

    const { t } = useTranslation()

    const [openIndex, setOpenIndex] = useState<number | null>(null)
    const handleClick = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    }

    const content: FAQItem[] = [
        {
            title: t('faq.content.faq7.title'),
            description: t('faq.content.faq7.description'),
        },
        {
            title: t('faq.content.faq1.title'),
            description: t('faq.content.faq1.description'),
        },
        {
            title: t('faq.content.faq2.title'),
            description: t('faq.content.faq2.description'),
        },
        {
            title: t('faq.content.faq3.title'),
            description: t('faq.content.faq3.description'),
        },
        {
            title: t('faq.content.faq4.title'),
            description: t('faq.content.faq4.description'),
        },
        {
            title: t('faq.content.faq5.title'),
            description: t('faq.content.faq5.description'),
        },
        {
            title: t('faq.content.faq6.title'),
            description: t('faq.content.faq6.description'),
        },
        {
            title: t('faq.content.faq8.title'),
            description: t('faq.content.faq8.description'),
        },
    ]

    const tents: {size: string, description: string}[] = t('tents', { returnObjects: true })

    console.log(tents)

    return (
        <Element
            name="faq"
            id="faq"
            className="w-full"
        >
            <Container>
                <div className="grid lg:grid-cols-3 gap-8 lg:gap-4" itemScope itemType="https://schema.org/FAQPage">
                    <div className="font-poppins flex flex-col gap-4">
                        <h2 className="font-bold text-primary text-6xl">{t('faq.title')}</h2>
                        <p>{t('faq.description')}. <a className="text-red-500 hover:underline" href="https://wa.me/5512997301700?text=Ol%C3%A1!+Vim+do+site+e+gostaria+de+tirar+algumas+dúvidas+sobre+os+serviços." target="_blank">{t('faq.description-link')}</a></p>
                    </div>

                    <div className="lg:col-span-2 grid gap-4">
                        {content.map((item, index) => (
                            <div key={index} className="w-full border-4 border-gray-200 rounded-lg lg:rounded-xl overflow-hidden font-poppins">
                                <div
                                    className="relative w-full p-2 lg:p-4 text-primary font-bold bg-gray-100 hover:bg-gray-200 hover:cursor-pointer collapse-title"
                                    onClick={() => handleClick(index)}
                                    itemScope
                                    itemProp="mainEntity"
                                    itemType="https://schema.org/Question"
                                >
                                    <h3 className="pr-8" itemProp="name">{item.title}</h3>
                                    <figure className="absolute right-4 top-1/2 -mt-2 w-4 h-4">
                                        <img
                                            src={IconCollapseArrow}
                                            alt="collapse"
                                            className={`w-full h-full ${openIndex === index ? '' : 'transform rotate-180'}`}
                                        />
                                    </figure>
                                </div>
                                {openIndex === index && (
                                    <div
                                        className="w-full p-2 lg:p-4 collapse-content"
                                        itemScope
                                        itemProp="acceptedAnswer"
                                        itemType="https://schema.org/Answer"
                                    >
                                        {item.description === 'tents' ? (
                                            <table className="border-slate-200 border-spacing-4 border-collapse w-full table-auto">
                                                <thead>
                                                    <th className="w-30 p-4 text-sm text-left">Tamanho</th>
                                                    <th className="w-30 p-4 text-sm text-right">Capacidade</th>
                                                </thead>
                                                <tbody>
                                                    {tents.map((tent) => (
                                                        <tr>
                                                            <td className="border-t border-slate-100 border-collapse p-4 text-sm border-spacing-4 text-left">{tent.size}</td>
                                                            <td className="border-t border-slate-100 border-collapse p-4 text-sm border-spacing-4 text-right">{tent.description}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>                                           
                                        ): (
                                            <p>{item.description}</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Container>

        </Element>
    )
}

export default FAQ