import React, {useRef} from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as Scroll } from "react-scroll";
import { Link } from "react-router-dom";
import ToolbarLogo from '../assets/images/header-logo.png';
import BrFlag from '../assets/images/flags/flag-brazil.svg';
import UsFlag from '../assets/images/flags/flag-usa.svg';
import Footer from "../partials/Footer";
import Icon from "../components/Icon";
import SocialIcons from "../components/SocialIcons";
import useWhatsapp from "../data/whatsapp";

interface LandingProps {
	children: React.ReactNode;
}

const Landing = ({children}: LandingProps) => {

	const [NavOpen, setNavOpen] = React.useState(false);

	React.useEffect(() => {
		const appNav = document.getElementById('app-nav');
		console.log(NavOpen);

		if (NavOpen) {
			appNav?.classList.add('max-lg:flex');
			appNav?.classList.remove('max-lg:hidden');
		} else {
			appNav?.classList.add('max-lg:hidden');
			appNav?.classList.remove('max-lg:flex');
		}

	}, [NavOpen]);

	const { t, i18n } = useTranslation();

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
	};

	const { number: whatsAppNumber, text: whatsAppMsgText } = useWhatsapp({ text: t('whatsapp.default') });

	return (
		<>
			<nav className="fixed top-0 left-0 w-full flex items-center justify-center z-30">
				<div className="bg-white w-full p-4 shadow-lg flex items-center justify-between flex-row">
					<Link to="/" className="logo flex items-center justify-center flex-row gap-2 uppercase font-bold">
						<div className="h-12 rounded-md">
							<img src={ToolbarLogo} alt={process.env.REACT_APP_NAME} className="w-full h-full object-contain" />
						</div>
						<h1 className="max-lg:hidden text-primary">{process.env.REACT_APP_NAME}</h1>
					</Link>

					<div
						className="lg:hidden text-slate-800 font-roboto font-bold flex items-center justify-center gap-2 flex-row hover:cursor-pointer"
						id="app-nav-open"
						onClick={() => setNavOpen(true)}
					>
						<span>MENU</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="w-8 h-8"
						>
							<line x1="3" y1="12" x2="21" y2="12" />
							<line x1="3" y1="6" x2="21" y2="6" />
							<line x1="3" y1="18" x2="21" y2="18" />
						</svg>
					</div>

					<div className="flex items-center gap-8 justify-center flex-row
									max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:w-full max-lg:h-full
									max-lg:bg-slate-900 max-lg:bg-opacity-90 max-lg:z-50
									max-lg:flex-col transition duration-500 max-lg:hidden
									max-lg:gap-16"
						id="app-nav"
					>
						<div
							className="py-1 px-2 rounded-md uppercase text-white font-bold font-roboto border-2 
										border-white lg:hidden hover:bg-white hover:text-slate-900 hover:cursor-pointer"
							id="app-nav-close"
							onClick={() => setNavOpen(false)}
						>
							{t('modal.close')}
						</div>
						
						<ul className="flex gap-2 items-center justify-center flex-row font-semibold
										font-roboto text-gray-800 max-lg:text-white max-lg:text-3xl
										max-lg:flex-col"
						>
							<li><LinkScroll smooth={true} onClick={() => setNavOpen(false)} to="about" className="z-10 relative max-lg:hover:underline hover:cursor-pointer hover:underline hover:text-primary">{t('menu.about')}</LinkScroll></li>
							<li><LinkScroll smooth={true} onClick={() => setNavOpen(false)} to="gallery" className="z-10 relative max-lg:hover:underline hover:cursor-pointer hover:underline hover:text-primary">{t('menu.tents')}</LinkScroll></li>
							<li><LinkScroll smooth={true} onClick={() => setNavOpen(false)} to="faq" className="z-10 relative max-lg:hover:underline hover:cursor-pointer hover:underline hover:text-primary">{t('menu.faq')}</LinkScroll></li>
							<li><LinkScroll smooth={true} onClick={() => setNavOpen(false)} to="contact" className="z-10 relative max-lg:hover:underline hover:cursor-pointer hover:underline hover:text-primary">{t('menu.contact')}</LinkScroll></li>
						</ul>

						<div className="flex flex-row gap-3 items-center justify-start">
							<SocialIcons iconClass="text-slate-700 w-[34x] h-[34x] p-2 bg-slate-200 rounded-md hover:bg-slate-600 hover:text-white hover:rotate-3 transition-all duration-300 ease-in-out" />
						</div>

						<div className="flex flex-row gap-2 lg:gap-4 items-center justify-start">
							<img src={BrFlag} alt={ t('locales.ptBr') } className="w-12 hover:cursor-pointer hover:rotate-6 transition-all duration-200 ease-in-out" onClick={() => handleLanguageChange('pt')} />
							<img src={UsFlag} alt={ t('locales.us') } className="w-12 hover:cursor-pointer hover:rotate-6 transition-all duration-200 ease-in-out" onClick={() => handleLanguageChange('en')} />
						</div>
					</div>
				</div>
			</nav>

			<main className="w-full">{children}</main>

			<a
				href={`https://wa.me/55${whatsAppNumber}?text=${whatsAppMsgText}`}
				className="fixed bottom-0 right-2 md:right-8 bg-green-500 text-white rounded-t-md flex items-center justify-center gap-4 p-4 uppercase font-bold hover:py-6 transition-all hover:cursor-pointer z-20"
			>
				<Icon icon="whatsapp" className="text-white" />
				{t('talk-to-us')}
			</a>

			<Footer />
		</>
	);
}

export default Landing;
