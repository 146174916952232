import React from 'react'
import { Element } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'
import iconCheckbox from '../assets/images/icons/icon-checkbox.svg'

type Feature = {
    title: string
    description: string
}

const Features = () => {
    
    const { t } = useTranslation()

    const features: Feature[] = [
        {
            title: t('features.feature1.title'),
            description: t('features.feature1.description'),
        },
        {
            title: t('features.feature2.title'),
            description: t('features.feature2.description'),
        },
        {
            title: t('features.feature3.title'),
            description: t('features.feature3.description'),
        },
        {
            title: t('features.feature4.title'),
            description: t('features.feature4.description'),
        },
        {
            title: t('features.feature5.title'),
            description: t('features.feature5.description'),
        },
        {
            title: t('features.feature6.title'),
            description: t('features.feature6.description'),
        },
    ];

    return (
        <Element
            className="w-full"
            name="features"
            id="features"
        >
            <Container>
                <h2 className="mb-4 lg:mb-8 text-primary font-poppins font-bold text-2xl lg:text-5xl max-w-[420px]">{t('features.title')}</h2>
                
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-4">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="flex items-start justify-start flex-row gap-4"
                        >
                            <img src={iconCheckbox} alt="checked" className="w-8 h-8" />
                            <div className="flex justfify-center flex-col items-start font-poppins">
                                <h3 className="text-lg font-bold text-red-600">{feature.title}</h3>
                                <p className="text-gray-600 text-sm">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </Element>
    )
}

export default Features
