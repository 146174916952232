import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import Container from '../components/Container'

import photo1 from '../assets/images/company/company-01.webp'
import photo2 from '../assets/images/company/company-02.webp'
import photo3 from '../assets/images/company/company-03.webp'
import photo4 from '../assets/images/company/company-04.webp'
import photo5 from '../assets/images/company/company-05.webp'
import photo6 from '../assets/images/company/company-06.webp'
import photo7 from '../assets/images/company/company-07.webp'
import photo8 from '../assets/images/company/company-08.webp'
import photo9 from '../assets/images/company/company-09.webp'
import photo10 from '../assets/images/company/company-10.webp'
import photo11 from '../assets/images/company/company-11.webp'
import photo12 from '../assets/images/company/company-12.webp'

const CompanyGallery = () => {
	const { t } = useTranslation()

    const photos = [
        photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9, photo10, photo11, photo12
    ]

    useEffect(() => {
        let lightbox: PhotoSwipeLightbox | null = null

        lightbox = new PhotoSwipeLightbox({
                gallery: '#company-gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
        })
        
        lightbox.init()

        return () => {
            lightbox?.destroy()
            lightbox = null
        }

    }, [])

    return (
        <Element
            name="gallery"
            id="gallery"
            className="w-full"
        >
            <Container>
                <h2 className="mb-4 lg:mb-8 text-primary font-poppins font-bold text-2xl lg:text-5xl max-w-[960px]">{t('gallery.company-title')}</h2>
            </Container>
            
            <div className="grid grid-cols-2 lg:grid-cols-3 tents-gallery" id="tents-gallery">
                {photos.map((tent, index) => (
                    <a
                        key={index}
                        data-pswp-width="600"
                        data-pswp-height="360"
                        href={tent}
                        target="_blank"
                        className="w-full h-44 lg:h-64 bg-cover bg-center overflow-hidden bg-slate-700 tents-gallery-item"
                    >
                        <img
                            src={tent}
                            alt={process.env.REACT_APP_NAME}
                            className="w-full h-full object-cover object-center transition-all duration-300 ease-in-out hover:scale-125 hover:cursor-pointer"
                        />
                    </a>
                ))}
            </div>
        </Element>
    )
}

export default CompanyGallery