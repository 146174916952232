import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import Container from '../components/Container'

import Tent1 from '../assets/images/tents/tenda-1.jpg'
import Tent2 from '../assets/images/tents/tenda-2.jpg'
import Tent3 from '../assets/images/tents/tenda-3.jpg'
import Tent4 from '../assets/images/tents/tenda-4.jpg'
import Tent5 from '../assets/images/tents/tenda-5.jpg'
import Tent6 from '../assets/images/tents/tenda-6.jpg'
import Tent7 from '../assets/images/tents/tenda-7.jpg'
import Tent8 from '../assets/images/tents/tenda-8.jpg'
import Tent9 from '../assets/images/tents/tenda-9.jpg'
import Tent10 from '../assets/images/tents/tenda-10.jpg'
import Tent11 from '../assets/images/tents/tenda-11.jpg'
import Tent12 from '../assets/images/tents/tenda-12.jpg'

const Gallery = () => {
	const { t } = useTranslation()

    const tents = [
        Tent1, Tent2, Tent3, Tent4, Tent5, Tent6, Tent7, Tent8, Tent9, Tent10, Tent11, Tent12
    ]

    useEffect(() => {
        let lightbox: PhotoSwipeLightbox | null = null

        lightbox = new PhotoSwipeLightbox({
                gallery: '#tents-gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
        })
        
        lightbox.init()

        return () => {
            lightbox?.destroy()
            lightbox = null
        }

    }, [])

    return (
        <Element
            name="gallery"
            id="gallery"
            className="w-full"
        >
            <Container>
                <h2 className="mb-4 lg:mb-8 text-primary font-poppins font-bold text-2xl lg:text-5xl max-w-[420px]">{t('gallery.title')}</h2>
            </Container>
            
            <div className="grid grid-cols-2 lg:grid-cols-3 tents-gallery" id="tents-gallery">
                {tents.map((tent, index) => (
                    <a
                        key={index}
                        data-pswp-width="600"
                        data-pswp-height="360"
                        href={tent}
                        target="_blank"
                        className="w-full h-44 lg:h-64 bg-cover bg-center overflow-hidden bg-slate-700 tents-gallery-item"
                    >
                        <img
                            src={tent}
                            alt={process.env.REACT_APP_NAME}
                            className="w-full h-full object-cover object-center transition-all duration-300 ease-in-out hover:scale-125 hover:cursor-pointer"
                        />
                    </a>
                ))}
            </div>
        
            <div className="mt-4 flex items-center justify-center flex-col w-full px-4">
                <div className="py-2 px-4 rounded-md bg-slate-100 font-poppins text-sm text-slate-700">{t('gallery.helper')}</div>
            </div>
        </Element>
    )
}

export default Gallery